<template>
  <div class="about">
    <div class="banner-box">
      <div class="banner">
        <img v-if="newsData.banners" :src="newsData.banners[0].bgUrl" />
        <span>{{$store.state.lang === 'zh' ? newsData.banners[0].titleLangZh : newsData.banners[0].title}}</span>
      </div>
    </div>
    <div class="container">
      <div class="lists">
        <div class="list-item" v-for="(item, index) in lists" @click="toDetails(item.title, item._id)" :key="index">
          <img 
            :class="
              ['hover-rubberBand','hover-jello', 'hover-shakeX','hover-pulse']
              [Math.floor((Math.random()*['hover-rubberBand','hover-jello','hover-shakeX','hover-pulse'].length))]
            "  :src="item.imgUrl" onerror="this.src='https://cvp-new-version.oss-accelerate.aliyuncs.com/3734acf81582074a23809456091a9ef.png'" />
          <div style="width: calc(100% - 214px)"> 
            <h3 style="text-transform:Capitalize;">{{ $store.state.lang=='zh'? item.titleLangZh: item.title }}</h3>
            <p class="mark">{{ $store.state.lang=='zh'? item.descLangZh: item.desc }}</p>
          </div>

          <div class="time">{{timeConvert(item.createTime)}}</div>
        </div>
        
      </div>

      <div>
        <v-pagination :total="newsFormData.total" :current-page='newsFormData.pageNo' :display='newsFormData.pageSize' @pagechange="pagechange"></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getNewsContent} from "@/apis"
import { timeConvert } from "@/utils"
import Pagination from '@/components/Pagination'

export default {
  components: {
    'v-pagination': Pagination,
  },
  data() {
    return {
      uri: require("@/assets/images/WechatIMG172.jpg"),
      lists: [
        // {
        //   img: require("@/assets/allImage/tucheng2.png"),
        //   title:
        //     "CLEARVUE PARTNERS LEADS $30M SERIES C+ ROUND IN SELF-SERVICE CAR WASH FIRM 1KM",
        //   mark:
        //     "Clearvue partners has led a us$30 million series c+ funding round in 1km, a shanghai-based self-service car wash solution provider. Proceeds of the new round will be used for technology development, market expansion and brand building. As a u.S. Dollar dominated fund, clearvue partners will also help 1km to Dollar dominated fund, clearvue partners will also help 1km toDollar dominated fund, clearvue partners will also help 1km to",
        // },
        // {
        //   img: require("@/assets/allImage/zu10_4.png"),
        //   title:
        //     "WILLIAM CHEN SHARED INSIGHTS AT THE ACCESS ALTS ASIA NEW YORK CONFERENCE",
        //   mark:
        //     "William Chen shared insights at the Access Alts Asia New York Conference",
        // },
        // {
        //   img: require("@/assets/allImage/zu10_6.png"),
        //   title:
        //     "WILLIAM CHEN SHARED HIS VIEWS ON “BUILDING A SMART ASIA” AT THE ACCESS ALTS LONDON CONFERENCE",
        //   mark:
        //     "Clearvue partners has led a us$30 million series c+ funding round in 1km, a shanghai-based self-service car wash solution provider. Proceeds of the new round will be used for technology development, market expansion and brand building. As a u.S. Dollar dominated fund, clearvue partners will also help 1km to Dollar dominated fund, clearvue partners will also help 1km toDollar dominated fund, clearvue partners will also help 1km to",
        // },
        // {
        //   img: require("@/assets/allImage/zu10_3.png"),
        //   title:
        //     "HARRY HUI SPOKE AT THE HKVCA 18TH CHINA PRIVATE EQUITY SUMMIT ON MAY 30, 2019",
        //   mark:
        //     "Clearvue partners has led a us$30 million series c+ funding round in 1km, a shanghai-based self-service car wash solution provider. Proceeds of the new round will be used for technology development, market expansion and brand building. As a u.S. Dollar dominated fund, clearvue partners will also help 1km to Dollar dominated fund, clearvue partners will also help 1km toDollar dominated fund, clearvue partners will also help 1km to",
        // },
        // {
        //   img: require("@/assets/allImage/zu10.png"),
        //   title:
        //     "HARRY HUI SPOKE AT THE HKVCA 18TH CHINA PRIVATE EQUITY SUMMIT ON MAY 30, 2019",
        //   mark:
        //     "Clearvue partners has led a us$30 million series c+ funding round in 1km, a shanghai-based self-service car wash solution provider. Proceeds of the new round will be used for technology development, market expansion and brand building. As a u.S. Dollar dominated fund, clearvue partners will also help 1km to Dollar dominated fund, clearvue partners will also help 1km toDollar dominated fund, clearvue partners will also help 1km to",
        // },
        // {
        //   img: require("@/assets/allImage/zu10_1.png"),
        //   title:
        //     "HARRY HUI SPOKE AT THE HKVCA 18TH CHINA PRIVATE EQUITY SUMMIT ON MAY 30, 2019",
        //   mark:
        //     "Clearvue partners has led a us$30 million series c+ funding round in 1km, a shanghai-based self-service car wash solution provider. Proceeds of the new round will be used for technology development, market expansion and brand building. As a u.S. Dollar dominated fund, clearvue partners will also help 1km to Dollar dominated fund, clearvue partners will also help 1km toDollar dominated fund, clearvue partners will also help 1km to",
        // },
      ],
      newsFormData: {
        pageNo: 1,
        pageSize: 10,
        total: 10,
      }
    };
  },
  computed:{
    newsData(){
      const news = this.$store.state.mainContent.news
      const lang = this.$store.state.lang
      let obj = {}
      if(news && news.extend){
       
        obj = {
          ...news,
          ...JSON.parse(news.extend)
        }

      }
      return obj
    }
  },
  methods: {
    timeConvert(time){
      return timeConvert(time)
    },
    // 切换页数
    pagechange(e){
      this.newsFormData.pageNo = e
      this.getNewsContent()
    },
    toDetails(title, id){
      this.$router.push({path: '/news-details/'+title, query:{id}})
    },
    async getNewsContent(){
      const res = await getNewsContent(this.newsFormData)
      
      this.newsFormData.total = res.total
      this.lists = []
      res.data.map( (item) => {
        this.lists.push({
          ...item,
          // img: require("@/assets/allImage/tucheng2.png"),
          // title: "CLEARVUE PARTNERS LEADS $30M SERIES C+ ROUND IN SELF-SERVICE CAR WASH FIRM 1KM",
          // mark: "Clearvue partners has led a us$30 million series c+ funding round in 1km, a shanghai-based self-service car wash solution provider. Proceeds of the new round will be used for technology development, market expansion and brand building. As a u.S. Dollar dominated fund, clearvue partners will also help 1km to Dollar dominated fund, clearvue partners will also help 1km toDollar dominated fund, clearvue partners will also help 1km to",
        },)
      })



      let top = document.documentElement.scrollTop;

      // 实现滚动效果 

      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 50;  

        if (top <= 0) {
          clearInterval(timeTop); //清除定时器

        }

      }, 20);
    }
  },
  mounted(){
    // document.title = this.$store.state.lang=='en'?'ClearVue': '新 闻'
    this.getNewsContent()

  }
};
</script>

<style lang="scss" scoped>
@import url("../../assets/custom.scss");

.banner-box {
  padding-top: 106px;
  .banner {
    position: relative;
    img {
      // height: 330px;
      width: 100%;
    }
    span {
      position: absolute;
      color: #fff;
      font-size: 50px;
      z-index: 1;
      top: 50%;
      transform: translate(0, -50%);
      right: 75px;
    }
  }
}

.lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 92px;

  .list-item {
    width: 50%;
    display: flex;
    max-height: 220px;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
    padding-right: 20px;
    cursor: pointer;
    .time{
      position: absolute;
      width: 214px;
      text-align: center;
      height: 36px;
      color: #fff;
      line-height: 36px;
      background-color: rgba(#000, .5);
      bottom: 0;
    }
    img {
      border: 1px solid #ccc;
      width: 214px;
      height: 214px;
      display: block;
      // box-shadow: 0px 0px 7px 0px rgba(10, 2, 4, 0.28);
      margin-right: 22px;
    }

    h5 {
      margin-bottom: 6px;
    }

    .mark {
      font-size: 19px;
      line-height: 25px;
      letter-spacing: 0px;
      color: #221815;
      position: relative;
      overflow: hidden;
    }
  }
}
</style>
